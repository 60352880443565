import logo from './logo.svg';
import './App.css';
import { Header, HeaderNew } from './component/Header';
import React from 'react'
import { Button } from 'react-bootstrap';
function App() {
const [tf,settf]=React.useState(true)
const buttonclick =()=>{
  tf?settf(false):settf(true)
}
  return (
    <>
    {tf?<Header/>:<HeaderNew/>}
    <Button onClick={()=>buttonclick()}>CHANGE</Button>
    </>
  );

}

export default App;
